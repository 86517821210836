<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="orgreport/List"
        :params="params"
        keyID="B907943869988E45F6C09646512F9872"
        :multiBtn="multiBtn"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="发送机构" prop="orgId">
          <Input v-model="params.orgName" clearable @on-clear="clearOrg('org')" readonly placeholder="发送机构">
            <Button slot="append" icon="ios-search" @click="choiceOrg('org')"></Button>
          </Input>
        </FormItem>
        <FormItem label="接收机构" prop="receiveOrgId">
          <Input v-model="params.receiveOrgName" clearable @on-clear="clearOrg('receiveOrg')" readonly placeholder="接收机构">
            <Button slot="append" icon="ios-search" @click="choiceOrg('receiveOrg')"></Button>
          </Input>
        </FormItem>
        <FormItem label="报告类型" prop="type">
          <Select v-model="params.type" placeholder="报告类型" clearable style="width: 100px">
            <Option v-for="(item,index) in baseData['机构报表']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="年份" prop="year">
          <ys-date-picker type="year" v-model="params.year" clearable style="width: 80px" placeholder="年份"></ys-date-picker>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="机构名称" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">生成报告</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="600" :title="detailID?'修改报告':'生成报告'">
      <Edit :mid="detailID" @on-ok="getList"></Edit>
    </ys-modal>
    <ys-modal v-model="orgFlag" :width="1200" title="选择机构">
      <org-component :limit="1" @on-ok="getData"></org-component>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/reportManager/month/detail";
import OrgComponent from "_c/org";
import {monthCommit, reGenReport} from "@/api/brief";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {
    Detail,
    OrgComponent,
    Edit: () => import('./edit.vue'),
  },
  data() {
    return {
      orgFlag: false,
      type: '',
      params: {
        orgId: '',
        receiveOrgId: '',
        orgName: '',
        receiveOrgName: '',
        year: new Date(),
        endAt: '',
        keyword: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {title: '报告名称', width: 250, key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {title: '发送机构', minWidth: 250, key: 'orgName', align: 'left'},
        {minWidth: 200, title: '接收机构', key: 'receiveOrgName', align: 'left'},
        {title: '状态', width: 80, key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '机构报表状态'}},
        {title: '类型', width: 70, key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '机构报表'}},
        {title: '归属', width: 70, key: 'index', align: 'center',},
        {title: '年份', width: 70, key: 'year', align: 'center',},
        {title: '发布人', width: 130, key: 'publishedUser', align: 'left',},
        {title: '发布日期', width: 160, key: 'publishedAt', align: 'left',},
        {width: 160, title: '创建时间', key: 'createdAt', align: 'center'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/reportManager/month/detail/' + (this.tableSelectItem.length === 1 ? this.tableSelectItem[0].id : null),
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 0
            },
            {
              click: () => this.delItem('/orgreport/Remove', {id: this.tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 0
            },
          ],
        },
        {
          children: [
            {
              click: () => this.reload(),
              title: '重新生成',
              icon: 'md-refresh-circle',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 0
            },
            {
              click: () => this.submit(),
              title: '提交报告',
              icon: 'md-cloud-upload',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 0
            },
          ],
        },
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    submit() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要提交这份报告么?提交后将无法修改报告信息!',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          monthCommit({id: this.tableSelectItem[0].id}).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功！'
              });
              this.getList();
            }
          })
        }
      })
    },
    reload() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要重新生成这份报告信息么？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          reGenReport({id: this.tableSelectItem[0].id}).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功！'
              });
              this.getList();
            }
          })
        }
      })
    },
    clearOrg(type) {
      this.params[type + 'Id'] = '';
      this.params[type + 'Name'] = '';
    },
    choiceOrg(type) {
      this.orgFlag = true;
      this.type = type;
    },
    getData(data) {
      this.params[this.type + 'Id'] = data[0].id;
      this.params[this.type + 'Name'] = data[0].iname;
    },
  }
}
</script>
